<template lang="pug">
.container
  b-loading(:is-full-page='false' v-model='isLoading')
  page-title(title='Reglas de uso del equipo')
  .card
    .card-content
      app-form(@submit='submit' :is-saving='isSaving' :horizontal='false')
        rule-base.mb-3(v-model='ruleBase' name-placeholder='Conducción nocturna')
        .card.mb-3
          .card-content
            div
              p.mb-4
                small Se considera uso de un vehículo si este reporta una velocidad superior a los 20Km/h o se moviliza una distancia superior a 300 metros.
              p Prohibir el uso del vehículo en este horario:
              .two-columns-fields.mt-5
                time-field(label='Hora inicial' v-model='usageRule.start' required)
                time-field(label='Hora final' v-model='usageRule.end' required)
              //- div.mt-4
                p.mb-2 Días de uso prohibido del equipo (proximamente)
                b-checkbox(v-for="day in ['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom']" :key='day' disabled) {{ day }}
        rule-output.mb-3(v-model='ruleOutputs')
</template>

<script>
import toastService from '@/services/toast.service'
import rulesService from '@/services/rules.service'
import { ruleTypeEnum } from '@/constants/enums'
import { rulesList } from '@/router/routes'

import RuleBase from './blocks/RuleBase'
import RuleOutput from './blocks/RuleOutput'

export default {
  components: { RuleBase, RuleOutput },
  data () {
    return {
      ruleBase: {
        type: ruleTypeEnum.Usage,
        name: '',
        devices: []
      },
      ruleOutputs: {},
      usageRule: {
        start: '22:00:00',
        end: '06:00:00'
      },
      isLoading: false,
      isSaving: false
    }
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      this.isLoading = true

      if (this.$route.params.id) {
        const promises = [rulesService.getOne(this.$route.params.id)]
        const [ruleResult] = await Promise.all(promises)
        if (ruleResult) {
          const { id, type, name, devices, emailActions, generateEvent, turnOffEngine, usageRules } = ruleResult
          this.ruleBase = { id, type, name, devices }
          this.usageRule = usageRules[0] || {}
          this.ruleOutputs = { emailActions, generateEvent, turnOffEngine }
        }
      }
      this.isLoading = false
    },
    async submit () {
      const usageRules = [this.usageRule]
      const rule = {
        ...this.ruleBase,
        ...this.ruleOutputs,
        usageRules
      }
      this.isSaving = true
      const result = await rulesService.save(rule)
      if (result) {
        toastService.show('Regla guardada')
        this.$router.push({ name: rulesList.name })
      }
      this.isSaving = false
    }
  }
}
</script>

<style lang="scss" scoped>
.two-columns-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
}
</style>
